import React, { FC } from 'react'
import Logo from '../../images/black-elephant-logo.svg'

const PageLoader: FC = () => (
  <div className="loader">
    <div className="ribbon ribbon--loader">
      <div className="ribbon-col"></div>
      <div className="ribbon-col"></div>
      <div className="ribbon-col"></div>
      <div className="ribbon-col"></div>
      <div className="ribbon-col"></div>
      <div className="ribbon-col"></div>
      <div className="ribbon-col"></div>
      <div className="ribbon-col"></div>
    </div>
    <Logo className="loader-logo" />
  </div>
)

export default PageLoader
