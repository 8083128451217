import React, { FC, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { ProjectCardT } from '../../common'

const ProjectCard: FC<ProjectCardT> = ({ image, title, info, url, video }) => {
  return (
    <article className={`project-card`}>
      <Link className="project-card-inner" to={url}>
        {video ? (
          <iframe
            src={`https://www.youtube.com/embed/${video}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
            frameBorder="0"
            style={{ border: 0 }}
            className="project-card__video"
          />
        ) : (
          <img className="project-card__image" src={image} />
        )}
        <header className="project-card-meta">
          <h3
            className="project-card-meta__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {info && info.area && (
            <p className="project-card-meta__area">{info.area}</p>
          )}
        </header>
      </Link>
    </article>
  )
}

export default ProjectCard
