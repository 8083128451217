import React from 'react'
import { SVG, easing } from '@svgdotjs/svg.js'
import Layout from '../components/layout'
import SEO from '../components/seo'
import site from '../_data/site.json'
import { Link, graphql, PageProps } from 'gatsby'
import ProjectCard from '../components/project-card'

import {
  ProjectCardT,
  ProjectImageT,
  ProjectInfoT,
  ProjectT,
  ProjectVideoT,
} from '../common'
import PageLoader from '../components/page-loader'
import useElementSize from '../hooks/useElementSize'
import useHeaderLines from '../hooks/useHeaderLines'
import getAbsoluteOffset from '../utils/getAbsoluteOffset'

type Data = {
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: {
          title: string
          images: Array<ProjectImageT>
          slug: string
          info: ProjectInfoT
          video: ProjectVideoT
        }
      }
    }[]
  }
}

const IndexPage: React.FC<PageProps<Data>> = ({ data, location }) => {
  const { about } = site.pages
  const projects = data.allMarkdownRemark.edges
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(document.querySelector('.page--about'))
      const whoWeAreRect = getAbsoluteOffset(
        document.querySelector('.who-we-are'),
      )
      const projectsOneRect = getAbsoluteOffset(
        document.querySelector('#projects-1'),
      )
      const projectsTwoRect = getAbsoluteOffset(
        document.querySelector('#projects-2'),
      )
      const missionRect = getAbsoluteOffset(document.querySelector('.mission'))
      const projectsThreeRect = getAbsoluteOffset(
        document.querySelector('#projects-3'),
      )

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .fill('none')
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      const topPolyline = svg
        .polyline([
          ...headerLines,

          pageRect.left,
          headerRect.bottom,

          pageRect.left,
          whoWeAreRect.bottom,

          pageRect.right,
          whoWeAreRect.bottom,

          pageRect.right,
          projectsOneRect.bottom - projectsOneRect.height / 3,

          document.body.clientWidth,
          projectsOneRect.bottom - projectsOneRect.height / 3,
          ,
        ])
        .stroke({
          width: 0,
        })
      const topPolylineLength = topPolyline.node.getTotalLength()
      topPolyline
        .attr({
          'stroke-width': 5,
          'stroke-dashoffset': topPolylineLength,
          'stroke-dasharray': topPolylineLength,
        })
        .animate({ duration: 3000, delay: 2000 })
        .ease(easing['<>'])
        .attr({
          'stroke-dashoffset': 0,
        })

      const bottomPolyline = svg
        .polyline([
          0,
          projectsTwoRect.top,

          projectsTwoRect.right,
          projectsTwoRect.top,

          projectsTwoRect.right,
          projectsTwoRect.bottom,

          projectsTwoRect.left,
          projectsTwoRect.bottom,

          projectsTwoRect.left,
          missionRect.bottom,

          missionRect.right,
          missionRect.bottom,

          missionRect.right,
          projectsThreeRect.bottom,

          document.body.clientWidth,
          projectsThreeRect.bottom,
        ])
        .stroke({
          width: 0,
        })
      const bottomPolylineLength = bottomPolyline.node.getTotalLength()
      bottomPolyline
        .attr({
          'stroke-width': 5,
          'stroke-dashoffset': bottomPolylineLength,
          'stroke-dasharray': bottomPolylineLength,
        })
        .animate({ duration: 3000, delay: 3000 })
        .ease(easing['<>'])
        .attr({
          'stroke-dashoffset': 0,
        })

      return () => svg.remove()
    }
  }, [size])

  const getProject = (title: string) => {
    const filteredProject = projects.filter(
      p => p.node.frontmatter.title === title,
    )
    if (filteredProject.length < 1) return null

    return filteredProject[0].node.frontmatter
  }

  const featuredProject1 = getProject('China CITIC Bank')
  const featuredProject2 = getProject('Bank of China')
  const featuredProject3 = getProject('Bottomline')
  const featuredProject4 = getProject('Hunter')

  return (
    <>
      <PageLoader />
      <Layout>
        <SEO title="About" />
        <main ref={pageRef} className={`container page--about`}>
          <div className="who-we-are">
            <h1 className="h2 who-we-are__title">
              {about['who-we-are'].heading}
            </h1>
            <div
              className="who-we-are__content"
              dangerouslySetInnerHTML={{ __html: about['who-we-are'].content }}
            ></div>
          </div>
          <div id="projects-1" className="projects">
            <ProjectCard
              // video={featuredProject1.video && featuredProject1.video[0].id}
              image={featuredProject1.images[0].url}
              title={featuredProject1.title}
              info={featuredProject1.info}
              url={featuredProject1.slug}
            />
          </div>
          <div className="ethics-and-values">
            <h2 className="ethics-and-values__title">{about.ethics.heading}</h2>
            <div
              className="ethics-and-values__content"
              dangerouslySetInnerHTML={{ __html: about.ethics.content }}
            />
          </div>
          <div id="projects-2" className="projects grid grid-col-2">
            <ProjectCard
              // video={featuredProject2.video && featuredProject2.video[0].id}
              image={featuredProject2.images[0].url}
              title={featuredProject2.title}
              info={featuredProject2.info}
              url={featuredProject2.slug}
            />
            <ProjectCard
              // video={featuredProject3.video && featuredProject3.video[0].id}
              image={featuredProject3.images[0].url}
              title={featuredProject3.title}
              info={featuredProject3.info}
              url={featuredProject3.slug}
            />
          </div>
          <div className="mission">
            <div
              className="mission__content"
              dangerouslySetInnerHTML={{ __html: about.mission.content }}
            />
            <Link className="link link--btn" to="/our-services">
              Learn about our services
            </Link>
          </div>
          <div id="projects-3" className="projects">
            <ProjectCard
              // video={featuredProject4.video && featuredProject4.video[0].id}
              image={featuredProject4.images[0].url}
              title={featuredProject4.title}
              info={featuredProject4.info}
              url={featuredProject4.slug}
            />
          </div>
        </main>
      </Layout>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query projectsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(projects)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            video {
              id
              featured
            }
            images {
              url
              featured
              alt
            }
            info {
              area
            }
          }
        }
      }
    }
  }
`
